$primary-color: #fff;
$gray: #eee;
$purple: #ffe8fc;
$blue: #0d6efd;
$yellow: #ffe391;
$dark: gray;
$hover: rgb(233, 233, 233);

@mixin cardShow {
  transition: all 0.2s ease-in-out;
  border-top: 1px solid var(--border-primary-color);
  border-left: 1px solid var(--border-primary-color);
  border-bottom: 1px solid var(--border-second-color);
  border-right: 1px solid var(--border-second-color);
  box-shadow: -3px -3px 5px 0px var(--shadow-primary-color),
    5px 5px 10px -4px var(--shadow-second-color);
}
@mixin cardShowmini {
  transition: all 0.2s ease-in-out;
  border-top: 1px solid var(--border-primary-color);
  border-left: 1px solid var(--border-primary-color);
  border-bottom: 1px solid var(--border-second-color);
  border-right: 1px solid var(--border-second-color);
  box-shadow: -2px -2px 3px -1px var(--shadow-primary-color),
    2px 2px 3px -1px var(--shadow-second-color);
}

@mixin Input {
  box-shadow: inset 2px 2px 5px -2px var(--shadow-second-color),
    inset -2px -2px 5px -2px var(--shadow-primary-color);
  border-top: 1px solid var(--border-primary-color);
  border-left: 1px solid var(--border-primary-color);
  border-bottom: 1px solid var(--border-second-color);
  border-right: 1px solid var(--border-second-color);
  background: var(--main-bg-color);
}
@mixin Active {
  box-shadow: inset 2px 2px 5px -2px var(--shadow-second-color),
    inset -2px -2px 5px -2px var(--shadow-primary-color);
  background: var(--main-hover-color);
}
@mixin Checkbox {
  box-shadow: inset 2px 2px 4px -2px var(--shadow-second-color),
    inset -2px -2px 4px -2px var(--shadow-primary-color);
  // border-top: 1px solid var(--border-primary-color);
  // border-left: 1px solid var(--border-primary-color);
  // border-bottom: 1px solid var(--border-second-color);
  // border-right: 1px solid var(--border-second-color);
  background: var(--main-bg-color);

  border-top: 1px solid var(--border-second-color);
  border-left: 1px solid var(--border-second-color);
  border-bottom: 1px solid var(--border-primary-color);
  border-right: 1px solid var(--border-primary-color);
}
@mixin Active2 {
  box-shadow: inset 2px 2px 5px -2px var(--shadow-second-color),
    inset -2px -2px 5px -2px var(--shadow-primary-color);
  background: var(--main-hover-color) !important;
}
@mixin TextShadow {
  color: transparent;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.2);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  background-color: var(--main-text-color);
}

.hover-bg {
  background: var(--main-hover-color);
}
.hover-text {
  &:hover {
    color: var(--main-hover-color);
  }
}

.dashboard.component {
  background: #fff;
  border: 1px solid #eee;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  &:hover {
    box-shadow: 0px 7px 10px -5px rgba(51, 51, 51, 0.26);
    // border-color:#0088ba63
  }
}
.task {
  margin: 10px 20px;
  div {
    background: transparent 1;
    height: 250px;
  }
}
