@import './../../../scss/variable.scss';
@import './../../../scss/responsive.scss';

/*UI */
.card-ui {
  @include cardShow();
  background: var(--main-bg-color);
  border-radius: 10px;
  padding: 0.5rem;
  position: Relative;
  min-height: 300px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  transition: all 0.2s ease-in-out;
  .card-ui-imageContainer {
    background-size: cover;
    max-height: 200px;
    min-height: 100px;
    height: 100%;
    border-radius: 5px;
    @include cardShow();
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
  }
  .card-ui-cat {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 5px;
    span {
      border-radius: 3px;
      padding: 0.25rem 0.5rem;
      background: rgba($color: $yellow, $alpha: 0.7);
      position: relative;
      float: left;
      margin-left: 5px;
      font-weight: 100;
    }
  }
  .card-ui-title h5,
  .card-ui-content {
    padding: 0.5rem;
    text-align: left;
    color: var(--main-text-color);
  }
  .card-ui-title h5 {
    margin-bottom: 0;
  }
  .card-ui-content {
    padding-bottom: 2rem;
  }
  .card-ui-action {
    width: 100%;
    display: flex;
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
    ul {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      padding: 0;
      margin: 0;
      position: relative;
      padding: 0.5rem 0;
      li {
        margin: 0 0.25rem;
        a.card-ui-item {
          // @include cardShowmini();
          border-radius: 8px;
          padding: 8px 18px;
          background: var(--main-bg-color);
          transition: all 0.2s ease-in-out;
          cursor: pointer;
          &:hover {
            text-decoration: none;
            background: var(--main-hover-color);
            color: inherit;
          }
          &:active {
            box-shadow: inset 2px 2px 5px -2px var(--shadow-second-color),
              inset -2px -2px 5px -2px var(--shadow-primary-color);
          }
        }
      }
    }
    .card-ui-author {
      display: flex;
      padding: 0.5rem 0;
      margin: 0 auto;
      align-items: center;
      cursor: pointer;
      color: var(--main-text-color);
      span {
        font-size: 0.8em;
      }
      svg {
        font-size:1rem;
        margin-bottom:2px;
      }
      &:hover {
        span {
          color: var(--main-hover-color);
        }
      }
    }
  }
}

/*Footer*/

.footer {
  margin-top: 20px;
  padding: 20px 12px;

  // border-top: 1px solid #bbb;
  background: var(--main-bg-color);
  box-shadow: 0 0px 5px -3px #333;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .footer-left,
  .footer-right {
    ul {
      height: 100%;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    li {
      width: 100%;
      a {
        display: block;
        text-decoration: none;
        padding: 0.5rem 1rem;
        width: 100%;
        cursor: pointer;
        background: transparent;
        color: var(--main-text-color);
        &:hover {
          background: var(--main-hover-color);
        }
      }
    }
  }
  .footer-divide {
    @include cardShow();
    width: 1px;
    height: 100%;
  }

  // Scroll top
  .scroll-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    @include cardShow();
    background: var(--main-bg-color);
    opacity: 0.3;
    transition: all 0.2s ease-in-out;
    z-index: 2;
    &:hover {
      opacity: 1;
    }
    span {
      font-size: 1.3rem;
    }
  }
}
.footer-copy-right {
  margin-top: 20px;
  p {
    color: var(--main-text-color);
  }
}

/*Footer*/
/*Header*/
// Khai báo kích thước tương ứng với màn hình thiết bị

.header {
  @include Responsive(SM) {
    justify-content: space-between;
  }
  @include Responsive(XS) {
    justify-content: space-between;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  background: var(--second-bg-color);
  padding: 10px 20px;
  z-index: 9999;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 0 5px -3px #333;
  .logo {
    img {
      max-height: 40px;
      width: auto;
    }
  }
  .menu-desktop {
    align-self: center;
    display: none;
    width: 100%;
    @include Responsive(MD) {
      display: block;
    }
    @include Responsive(XL) {
      display: block;
    }
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      list-style-type: none;
      margin: 0 auto;
      padding: 0;
      border-radius: 100%;
      li {
        a {
          padding: 8px 25px;
          border-radius: 99px;
          min-width: 100px;
          transition: 0.2s ease-in-out;
          cursor: pointer;
          position: relative;
          @include TextShadow();
          &:before {
            @include Checkbox();
            position: absolute;
            content: '';
            height: 12px;
            width: 12px;
            transform: translate(-50%, 50%);
            background: transparent;
            bottom: 0;
            left: 50%;
            transition: 0.2s ease-in-out;
            border-radius: 50px;
          }
          &:hover {
            color: inherit;
            &:before {
              background: var(--main-hover-color);
            }
          }
        }
        a.is_active {
          color: inherit;
          &:before {
            background: var(--main-hover-color);
          }
        }
      }
    }
  }
  .mode {
    font-size: var(--text-size);
    float: right;
    z-index: -1;
    .btn-mode {
      height: 32px;
      width: 32px;
      position: relative;
      @include cardShowmini();
      background: var(--second-bg-color);
      border-radius: 99px !important;
      i {
        color: var(--main-text-color);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 8px;
      }
    }
  }
  .menu-mobile {
    display: none;
    align-self: center;
    div.menu-toggle {
      height: 30px;
      width: 35px;
      position: relative;
      cursor: pointer;
      &:hover {
        color: var(--main-hover-color);
        span {
          background: var(--main-hover-color);
        }
      }
      span {
        position: absolute;
        background: var(--main-text-color);
        height: 5px;
        width: 5px;
        border-radius: 50px;
        transform: translate(-50%, 0);
        &:nth-child(1) {
          top: 50%;
          left: 0;
          animation: hamburger 1.8s linear infinite;
        }
        &:nth-child(2) {
          top: 50%;
          left: 50%;
          animation: hamburger 1.8s linear infinite 0.2s;
        }
        &:nth-child(3) {
          top: 50%;
          left: 100%;
          animation: hamburger 1.8s linear infinite 0.4s;
        }
      }
    }

    &:active {
    }
    .menu-dropdown {
      // transition: all 0.2s ease-in-out;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      top: 0;
      left: 0;
      background: var(--primary-color);
      height: 100vh;
      transition: all 0.2s ease-in-out;
      width: 0;
      ul {
        background: var(--second-bg-color);
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-around;
        margin: 0;
        height: 100vh;
        li.nav-item-active {
          a {
            @include Active();
            color: inherit;
          }
        }
        li.nav-item {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          a {
            font-size: 1.2rem;
            border-radius: 99px;
            min-width: 150px;
            min-height: 35px;
            padding: 8px 12px;
            &:not(.nav-link-item-active) {
              @include cardShow();
            }
            &:active {
              @include Active();
              color: inherit;
            }
          }
        }
      }
    }
    .active-header {
      visibility: visible;
      opacity: 1;
      width: 100%;
    }
    @include Responsive(SM) {
      display: flex;
    }
    @include Responsive(XS) {
      display: flex;
    }
  }
}
@keyframes hamburger {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0%);
  }
}

/*Header*/
/*Layout*/
.list-group {
  @include cardShow();
  // background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  position: Relative;
  .list-group-item {
    padding: 0;
    border: none;
    background: var(--main-bg-color);
    &:not(.active) {
      border-top: 1px solid var(--border-second-color);
    }
    &:not(:last-child) {
      border-bottom: 1px solid rgba(187, 187, 187, 0.5);
    }
  }
  .active {
    background: var(--main-hover-color);
  }
  a {
    display: block;
    text-decoration: none;
    padding: 0.5rem 1rem;
    width: 100%;
    color: var(--main-text-color);
    transition: 0.3s ease-in-out;
    border: 2px solid transparent;
    outline: none;
    border-radius: 5px;
    &:hover {
      background: var(--main-hover-color);
    }
  }
}
.layout-icon {
  display: flex;
  cursor: pointer;
  p {
    padding: 0 5px;
    margin: 0;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
  }
  svg {
    transition: all 0.2s ease-in-out;
    font-size: 1.3rem;
  }
  &:hover {
    p {
      color: var(--main-hover-color);
    }
    .icon-hover {
      color: var(--main-hover-color);
    }
  }
}
.hover {
  cursor: pointer;
  color: var(--main-text-color);
  transition: all 0.2s ease-in-out;
  &:hover {
    color: var(--main-hover-color);
    width: 40px;
  }
}
.image-container {
  border-top: 2px solid var(--border-primary-color);
  border-left: 2px solid var(--border-primary-color);
  border-bottom: 2px solid var(--border-second-color);
  border-right: 2px solid var(--border-second-color);
  border-radius: 12px;
}

/*Layout */
/*Pagination */
.pagination {
  padding-top: 20px;
  height: auto;
  .pagi-item {
    width: auto;
    height: auto;
    margin: 5px;
    a {
      transition: all 0.2 ease-in-out;
      padding: 10px 16px;
      border-radius: 999px;
      @include cardShowmini();

      &:hover {
        background: var(--main-hover-color);
      }
      &:active {
        @include cardShowmini();
      }
    }
    &.active {
      a {
        @include Active();
        background: var(--main-hover-color);
      }
    }
  }
}
/*Pagination */

/* Side bar*/
.side-search {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .side-search-form {
    position: relative;
    width: 80%;
    input {
      @include Input();
      transition: all 0.3s linear;
      border-radius: 50px;
      &:focus {
        border-color: var(--main-hover-color);
      }
    }
    button[type='submit'] {
      font-weight: bold;
      // font-size: 1.2rem;
      color: var(--main-text-color);
      border: none;
      outline: none;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      transition: all 0.2s ease-in-out;
      @extend .hover-text;
      // &:active,
      // &:focus {
      //   color: var(--main-hover-color);
      //   border: none;
      // }
    }
  }
  .side-search-filter {
    align-self: center;
    color: var(--main-text-color);
    transition: all 0.2s ease-in-out;
    @extend .hover-text;
  }
}

/* Side bar*/
