.action-page {
  padding: 20px 10px;
  h4 {
    padding: 10px 10px;
    text-align: left;
  }
  .img-features {
    height: 200px;
    width: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    span {
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      padding: 8px 2px;
      color: #eee;
    }
    input[type='file'] {
      position: absolute;
      bottom: 0;
      left: 0;
      color: transparent;
    }
  }
  .mb-3 {
    text-align: left;
  }
}
