.App {
  text-align: center;
  /* background-image: url("https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80");
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center; */
  background: var(--main-bg-color);
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
}
/* Animated */
.item-transition-enter {
  opacity: 0;
}
.item-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-transition-exit {
  opacity: 1;
}
.item-transition-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

/* Animated */
/* Loading screen */
.loading {
  /* position: fixed; */
  /* top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  position: relative;
  height: 100px;
  background: rgba(250, 250, 250, 0.4);
  overflow: hidden;
  z-index: 99;
}
.loading .loading-item {
  width: 400px;
  height: 400px;
  border: 1px solid #000;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.2);
  /* position: fixed; */
  position: absolute;
}
.pupil {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  background: #000;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.iris {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  border-radius: 50%;
}
.tomoes {
  height: 100%;
  width: 100%;
  animation: rotateIris 1.4s linear infinite;
}

.tomoe-area {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tomoe {
  width: calc(400px / 6.5);
  height: calc(400px / 6.5);
  position: absolute;
  background-color: #000;
  border-radius: 50%;
  left: 50%;
  top: -16.6%;
  transform: translate(-50%);
}
.tomoe::before {
  content: "";
  position: absolute;
  top: -30.3%;
  left: 3.1%;
  width: calc(var(--tomoe-size) * 1.51);
  height: calc(var(--tomoe-size) * 1.69);
  border-radius: 50%;
  border-top: calc(var(--tomoe-size) / 2.6) solid #000;
  border-left: calc(var(--tomoe-size) / 9.7) solid transparent;
  transform: rotate(-35deg);
}
.tomoe-area:first-child {
  transform: translate(-50%, -50%);
}
.tomoe-area:nth-child(2) {
  transform: translate(-50%, -50%) rotate(120deg);
}
.tomoe-area:nth-child(3) {
  transform: translate(-50%, -50%) rotate(-120deg);
}

@keyframes rotateIris {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loadingscreen {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Loading screen */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a {
  text-decoration: none;
  color: inherit;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
}
.card {
  border: 1px solid #eee;
  background: rgba(255, 255, 255, 1);
  padding: 12px;
  flex-basis: 40%;
  max-width: calc((100% - 12px) / 2);
  margin: 8px;
  max-width: 350px;
  width: auto;
  border-radius: 5px;
  position: relative;
  max-height: 300px;
  min-height: 250px;
  transition: all 0.2s linear;
}
.action {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.action .btn {
  color: #333;
  text-decoration: none;
  outline: none;
  border: none;
  float: left;
  /* background-color: rgb(233, 233, 233); */
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
}
.content {
  text-align: left;
}
.title {
  text-align: left;
  font-weight: 300;
}

.card:hover {
  box-shadow: 0 10px 15px -12px black;
  border: 1px solid rgb(201, 201, 201);
}
