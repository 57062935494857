@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap");
* {
  margin: 0;
  padding: 0;
}
:root {
  --background: #eee;
  --text-color: #333;
  --text-size: 16px;
  --font-text: "Bai Jamjuree", sans-serif;

  --tomoe-size: calc(400px / 6.5);

  --primary-color: #f8f8f8;
  /* --main-hover-color: #ffe391; */

  --light-color: #212529;
  --light-background-color: #eee;
  --light-second-color: #727475;
  --light-second-background-color: #f1f1f1;

  --dark-color: #e4e6eb;
  --dark-background-color: #232420;
  --dark-second-color: #f5f6fa;
  --dark-second-background-color: rgb(54, 54, 54);
  --dark-shadow-primary-color: #464646;
}
.light {
  --main-bg-color: var(--light-background-color);
  --second-bg-color: var(--light-second-background-color);
  --main-text-color: var(--light-color);
  --second-text-color: var(--light-second-color);
  /* --main-hover-color: #ffe391; */
  --main-hover-color: #ffc75f;
  --shadow-primary-color: #fff;
  --shadow-second-color: rgb(87, 87, 87);
  --border-primary-color: #eee;
  --border-second-color: rgb(206, 206, 206);
}
.dark {
  --main-bg-color: var(--dark-background-color);
  --second-bg-color: var(--dark-second-background-color);
  --main-text-color: var(--dark-color);
  --second-text-color: var(--dark-second-color);
  /* --main-hover-color: #00755D; */
  --main-hover-color: #2d88ff;
  --shadow-primary-color: var(--dark-shadow-primary-color);
  --shadow-second-color: rgb(0, 0, 0);
  --border-primary-color: rgb(86, 86, 86);
  --border-second-color: rgb(35, 35, 35);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
span,
td,
th {
  color: var(--main-text-color);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  font-family: var(--font-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  height: auto;
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10rem;
}
::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track-piece:start {
  background: none;
}
::-webkit-scrollbar-track-piece:end {
  background: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
/* .sticky-bottom-cus {
  position:fixed;
  bottom:0;
  left:0;
  z-index:1;
  width:100%;
  height:100px;
  background: linear-gradient(0deg, var(--main-bg-color), transparent)
} */
